import i18next from './i18next';

function loadFacebookSDK() {
  const script = document.createElement('script');
  const sdkLang = (() => {
    switch (i18next.language) {
      case 'zh-cn':
        return 'zh_CN';
      case 'zh-hant':
      case 'zh-tw':
      case 'zh-hk':
        return 'zh_TW';
      default:
        return 'en_US';
    }
  })();
  script.src = `https://connect.facebook.net/${sdkLang}/sdk.js`;
  script.async = true;
  document.body.append(script);
}

const init = (initCallback) => {
  if (window.FB) {
    initCallback();
  } else if (window.fbAsyncInit) {
    const originalFbAsyncInit = window.fbAsyncInit;
    window.fbAsyncInit = () => {
      originalFbAsyncInit();
      initCallback();
    };
  } else {
    window.fbAsyncInit = () => {
      FB.init({
        appId: window.mainConfig.facebookAppId,
        xfbml: true,
        version: window.mainConfig.facebookSdkVersion,
      });
      window.globalSDKObserver.FB.notify();
      initCallback();
    };
    loadFacebookSDK();
  }
};

export default { init };
