import i18next from 'i18next';

const newInstance = i18next.createInstance(
  {
    debug: process.env.NODE_ENV === 'development',
    ns: ['widget'],
    defaultNS: 'widget',
    fallbackLng: 'en',
    lowerCaseLng: true,
  },
  () => {},
);

if (window.mainConfig?.assetHost) {
  // eslint-disable-next-line no-undef
  __webpack_public_path__ = window.mainConfig.assetHost;
}
const lang = window.mainConfig?.localeData?.loadedLanguage?.code || 'en';

async function loadLocale(languageCode) {
  try {
    const mod = await import(`../locale/${languageCode}.js`);
    const { default: locale } = mod;
    Object.keys(locale).forEach((ns) => {
      newInstance.addResourceBundle(languageCode, ns, locale[ns]);
    });
    newInstance.changeLanguage(languageCode);
  } catch (err) {
    loadLocale('en');
  }
}

loadLocale(lang);

export default newInstance;
