/**
 * EMAIL_REGEX
 * 1. the rules before @, should be either
 *  A. username has following rules
 *    I. first character cannot be banned char, regex: [^+\-_!#$%*^/?`{|}~<>()[\]\\.,;:\s@"]
 *    II. the rest characters should match at least one of following two rules, regex: (?:(?:[^+!#$%*^/?`{|}~<>()[\]\\.,;:\s@"]|([+.\-_])(?!\1))*)
 *      a. not banned character, regex: [^+\-_!#$%*^/?`{|}~<>()[\]\\.,;:\s@"]
 *      b. +. are allowed if and only if there are no two same +. in a row, regex: ([+.])(?!\1)(?!@)
 *      c. + is allowed in the tail, regex: [+](?=@)
 *  B. the words surrounded by "", ex. "test", regex: (?:"[^!#$%*^/?`{|}~]+"))
 * 2. the rules after @, should be either
 *  A. IP address with brackets regex: (\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])
 *  B. domain name regex: (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})
 */
const EMAIL_REGEX =
  /^(?:[^+\-_!#$%*^/?`{|}~<>()[\]\\.,;:\s@"](?:(?:[^+!#$%*^/?`{|}~<>()[\]\\.,;:\s@"]|([+.])(?!\1)(?!@)|[+](?=@))*)|(?:"[^!#$%*^/?`{|}~]+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * EMAIL_MOBILE_REGEX
 * one of following two string should be allowed
 * 1. pure number string
 * 2. EMAIL_REGEX
 */
const EMAIL_MOBILE_REGEX =
  /^\d+$|^(?:[^+\-_!#$%*^/?`{|}~<>()[\]\\.,;:\s@"](?:(?:[^+!#$%*^/?`{|}~<>()[\]\\.,;:\s@"]|([+.])(?!\1)(?!@)|[+](?=@))*)|(?:"[^!#$%*^/?`{|}~]+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * SIMPLE_MOBILE_REGEX
 * 1. pure number string
 */
const SIMPLE_MOBILE_REGEX = /^\d+$/;

/**
 * W3C_EMAIL_REGEX
 * 1. the regex is from w3c
 * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
 */
const W3C_EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const MOBILE_BARCODE_REGEX = /^\/[A-Z0-9+\-.]{7}$/;

export default {
  EMAIL_REGEX,
  EMAIL_MOBILE_REGEX,
  W3C_EMAIL_REGEX,
  SIMPLE_MOBILE_REGEX,
  MOBILE_BARCODE_REGEX,
};
