/**
 * Get static source url
 * @param {*} path pathname
 * @returns static source url
 */
export const getStaticUrl = (path) => {
  const fullPath = path.replace(/(^\/+)(.*)/, '$2');
  return `${window.mainConfig.staticResourceHost}${fullPath}`;
};

/**
 * Get product media image service url
 * @param {object} media - product media object
 * @param {object} options - image url format options
 * @field {string} options.size - image size
 * @returns
 */
export function getMediaImageUrl(media, options = { size: '200x' }) {
  if (!media?.images?.original?.url) return '';

  const imageServiceEndpoint = document.querySelector(
    'link[title="image-service-origin"]',
  )?.href;
  if (!imageServiceEndpoint) {
    return media.images.original.url;
  }

  const webpHandler = function (ext) {
    return window.mainConfig.webpSupported
      ? 'webp?source_format=' + ext
      : ext + '?';
  };

  const extension = new URL(media.images.original.url).pathname
    .split('.')
    .pop();
  const isSupportedExtension = ['png', 'jpg', 'jpeg', 'gif'].some((ext) =>
    extension.startsWith(ext),
  );
  const id = media._id || media.id;
  if (id && isSupportedExtension) {
    const url = new URL(
      `/${window.mainConfig.merchantId}/${id}/${options.size}.${webpHandler(
        extension,
      )}`,
      imageServiceEndpoint,
    );
    return url.toJSON();
  } else {
    return media.images.original.url;
  }
}
