import notify from './notify';
import validator from './validator';
import recaptcha from './recaptcha';
import i18next from './i18next';
import facebook from './facebook';
import imageService from './image-service';
import translateModel from './translateModel';

window.shopline = Object.assign(window.shopline || {}, {
  notify,
  validator,
  recaptcha,
  i18next,
  facebook,
  translateModel,
  imageService,
});
