const getToken = (buttonId) =>
  new Promise((resolve, reject) => {
    const button = document.getElementById(buttonId);
    if (!button) {
      reject('button not found');
    }

    window[`onRecaptchaVerified_${buttonId}`] = function (token) {
      resolve(token);
    };
    window[`onRecaptchaRejected_${buttonId}`] = function () {
      reject('recatpcha rejected');
    };
    var widgetId = null;
    if (document.querySelector(`#${buttonId} .g-recaptcha`) === null) {
      const sitekey = window.mainConfig.recaptchaSiteKey;
      const recaptchaElement = document.createElement('div');
      recaptchaElement.className = 'g-recaptcha';
      recaptchaElement.style.visibility = 'hidden';
      button.append(recaptchaElement);
      widgetId = window.grecaptcha.render(recaptchaElement, {
        sitekey,
        size: 'invisible',
        callback: `onRecaptchaVerified_${buttonId}`,
        'error-callback': () => {
          window[`onRecaptchaRejected_${buttonId}`]();
        },
      });
      recaptchaElement.dataset.widgetId = widgetId;
    } else {
      widgetId = document.querySelector(`#${buttonId} .g-recaptcha`).dataset
        .widgetId;
      grecaptcha.reset(widgetId);
    }
    grecaptcha.execute(widgetId);
  });

export default { getToken };
